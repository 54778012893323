@import url('@spectrum-web-components/styles/all-medium-light.css');
@import url("tailwindcss/base");
@import url("tailwindcss/components");
@import url("tailwindcss/utilities");

body {
    font-family: 'Montserrat', sans-serif;
}

.modal {
    transition: opacity 0.25s ease;
}

body.modal-active {
    overflow-x: hidden;
    overflow-y: visible !important;
}
